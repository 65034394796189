.inputRounded .MuiOutlinedInput-root {
    border-radius: 25px;
    background-color: white;
  }

.center-login {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }